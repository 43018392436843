import React from 'react';
import { Button, BUTTON_TYPES, Popup, Typography } from '_atoms';
import { FeedbackCreation } from '_organisms/InterviewFeedback/FeedbackCreation';
import './style.scss';

export const PopupCreateFeedback = ({ open, onClose, onSave, feedback, userData }) => (
  <Popup className="popup-create-feedback mobile-full-size" open={open} title="Provide feedback" onClose={onClose}>
    <div className="user-info">
      <Typography.Text className="user-info__title">{userData.full_name_latin}</Typography.Text>
      <Typography.Caption className="user-info__subtitle">{userData.full_name_local}</Typography.Caption>
      <Typography.Caption className="user-info__subtitle">{userData.speciality}</Typography.Caption>
      {userData.link && (
        <a target="_blank" href={userData.link} rel="noreferrer">
          <Button type={BUTTON_TYPES.TEXT}>view candidate</Button>
        </a>
      )}
    </div>
    <FeedbackCreation initialValues={feedback} onFinish={onSave} onCancel={onClose} approver={feedback?.approver} />
  </Popup>
);
