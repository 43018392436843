import React, { useState } from 'react';
import { Tabs } from '_atoms';
import { SelfSubmittedCandidatesDashboard } from '_organisms';
import { useQuery } from 'helpers/useLocation';
import { MyActiveCandidates } from '../DashboardHRLead/DashboardHRLead';
import { TABS } from './constants';
import './style.scss';

export const DashboardRecruiter = () => {
  const query = useQuery();
  const [tab, setTab] = useState(query.tab || TABS[0].key);

  return (
    <div className="dashboard-recruiter">
      <Tabs className="dashboard-recruiter__tabs" activeTab={tab} onChange={setTab} items={TABS} />
      {
        {
          [TABS[0].key]: <MyActiveCandidates setTabsLoading={() => {}} />,
          [TABS[1].key]: <SelfSubmittedCandidatesDashboard dashboard="recruiter" />,
        }[tab]
      }
    </div>
  );
};
