export const ROLES = {
  SUPER_ADMIN: 'Super Admin',
  HR_LEAD: 'HR Lead',
  RECRUITER: 'Recruiter',
  RECRUITER_LEAD: 'Recruiter Lead',
  RESOURCE_MANAGER: 'Resource Manager',
  TEAM_LEAD: 'Team Lead',
  LOCATION_MANAGER: 'Location Manager',
  INTERVIEWER: 'Interviewer',
  SERVICE_HEAD: 'Service Head',
};

export const ACTIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  CRUD: 'crud',
};

export const SUBJECTS = {
  USER: 'USER',
  ACCOUNT: 'ACCOUNT',
  JOB: 'JOB',
  OPENING: 'OPENING',
  INTERVIEW: 'INTERVIEW',
  CANDIDATE: 'CANDIDATE',
  TASK: 'TASK',
  REMINDER: 'REMINDER',
  INTERACTION: 'INTERACTION',
  CANDIDATE_FILTERS: 'CANDIDATE_FILTERS',
};

export const UI = {
  CREATE_JOB_TEAM: 'CREATE_JOB_TEAM',
  ASSIGN_CANDIDATE: 'ASSIGN_CANDIDATE',
  ASSIGN_RECRUITER: 'ASSIGN_RECRUITER',
  UNASSIGN_RECRUITER: 'UNASSIGN_RECRUITER',
  UNASSIGN_RECRUITER_LEAD: 'UNASSIGN_RECRUITER_LEAD',
  APPROVE_DECLINE: 'APPROVE_DECLINE',
  EDIT_CANDIDATE_TIMELINE: 'EDIT_CANDIDATE_TIMELINE',
  ASSIGN_CANDIDATE_TO_ME: 'ASSIGN_CANDIDATE_TO_ME',
  EDIT_RESPONSIBLE_RECRUITER: 'EDIT_RESPONSIBLE_RECRUITER',
  POST_COMMENT_TO_CANDIDATE_ON_STEP: 'POST_COMMENT_TO_CANDIDATE_ON_STEP',
  ACTIONS_TL_SA: 'ACTIONS_TL_SA',
  PRINT_CV: 'PRINT_CV',
  CLONE_JOB: 'CLONE_JOB',
  ARCHIVE_JOB: 'ARCHIVE_JOB',
  ACTIONS_STEPS_WITHDRAW: 'ACTIONS_STEPS_WITHDRAW',
  ACTIONS_STEPS_ADD_APPROVER: 'ACTIONS_STEPS_ADD_APPROVER',
  ACTIONS_STEPS_ASSIGN_TO_STEP: 'ACTIONS_STEPS_ASSIGN_TO_STEP',
  ONLY_MU_CANDIDATES: 'ONLY_MU_CANDIDATES',
  LOCATION_MANAGER_PERMISSIONS: 'LOCATION_MANAGER_PERMISSIONS',
  ADD_INTERVIEW: 'ADD_INTERVIEW',
  ANALYTICS_PAGE: 'ANALYTICS_PAGE',
  SUBMIT_OFFER: 'SUBMIT_OFFER',
  SUBMIT_DECISION: 'SUBMIT_DECISION',
  SUBMIT_FINAL_DECISION: 'SUBMIT_FINAL_DECISION',
  ONLY_MY_OPENINGS: 'ONLY_MY_OPENINGS',
  DEFAULT_SERVICE: 'DEFAULT_SERVICE',
  EDIT_RECRUITER_REMINDER: 'EDIT_RECRUITER_REMINDER',
  ANALYTICS_REPORTING_TAB: 'ANALYTICS_REPORTING_TAB',
  ANALYTICS_SSC_TAB: 'ANALYTICS_SSC_TAB',
  CHANGE_TEAM_LEAD: 'CHANGE_TEAM_LEAD',
  ASSIGN_RECRUITER_LEAD: 'ASSIGN_RECRUITER_LEAD',
  // candidate list filters
  CANDIDATE_LIST_CANDIDATE_STATUS: 'CANDIDATE_LIST_CANDIDATE_STATUS',
  CANDIDATE_LIST_COUNTRY: 'CANDIDATE_LIST_COUNTRY',
  CANDIDATE_LIST_CITY: 'CANDIDATE_LIST_CITY',
  CANDIDATE_LIST_EXPERIENCE_LEVEL: 'CANDIDATE_LIST_EXPERIENCE_LEVEL',
  CANDIDATE_LIST_SPECIALTY: 'CANDIDATE_LIST_SPECIALTY',
  CANDIDATE_LIST_TAGS: 'CANDIDATE_LIST_TAGS',
  CANDIDATE_LIST_COMPANIES: 'CANDIDATE_LIST_COMPANIES',
  CANDIDATE_LIST_RESPONSIBLE_RECRUITER: 'CANDIDATE_LIST_RESPONSIBLE_RECRUITER',
  CANDIDATE_LIST_INTERACTED_BY: 'CANDIDATE_LIST_INTERACTED_BY',
  CANDIDATE_LIST_SOURCE: 'CANDIDATE_LIST_SOURCE',
  CANDIDATE_LIST_TEAM_LEAD: 'CANDIDATE_LIST_TEAM_LEAD',
  CANDIDATE_LIST_SERVICE: 'CANDIDATE_LIST_SERVICE',
  CANDIDATE_LIST_JOB: 'CANDIDATE_LIST_JOB',
  CANDIDATE_LIST_JOB_STEP: 'CANDIDATE_LIST_JOB_STEP',
  CANDIDATE_LIST_LAST_INTERACTION: 'CANDIDATE_LIST_LAST_INTERACTION',
  CANDIDATE_LIST_ADDED_BY: 'CANDIDATE_LIST_ADDED_BY',
  CANDIDATE_LIST_ADDED_DURING_PERIOD: 'CANDIDATE_LIST_ADDED_DURING_PERIOD',
  CANDIDATE_LIST_INTERVIEWED_BY: 'CANDIDATE_LIST_INTERVIEWED_BY',
  CANDIDATE_LIST_INTERVIEWED_DATE: 'CANDIDATE_LIST_INTERVIEWED_DATE',
  CANDIDATE_LIST_DISPLAY_SHOW_MORE: 'CANDIDATE_LIST_DISPLAY_SHOW_MORE',
  // candidate profile
  BLACKLIST_CANDIDATE: 'BLACKLIST_CANDIDATE',
  FIRE_CANDIDATE: 'FIRE_CANDIDATE',
  // job list filters
  JOB_LIST_COMPANIES: 'JOB_LIST_COMPANIES',
  JOB_LIST_SERVICE: 'JOB_LIST_SERVICE',
  JOB_LIST_TEAM_LEAD: 'JOB_LIST_TEAM_LEAD',
  JOB_LIST_JOB_STATUS: 'JOB_LIST_JOB_STATUS',
  JOB_LIST_RECRUITER: 'JOB_LIST_RECRUITER',
  JOB_LIST_OPENING_STATUS: 'JOB_LIST_OPENING_STATUS',
  JOB_LIST_OFFICE: 'JOB_LIST_OFFICE',
  JOB_LIST_EXPERIENCE_LEVEL: 'JOB_LIST_EXPERIENCE_LEVEL',
  ONLY_INTERACTED: 'ONLY_INTERACTED',
  // job creation form
  JOB_INFO_NAME: 'JOB_INFO_NAME',
  JOB_SERVICE: 'JOB_SERVICE',
  JOB_DESCRIPTION: 'JOB_DESCRIPTION',
  JOB_BENEFITS: 'JOB_BENEFITS',
  JOB_TEAM_LEAD: 'JOB_TEAM_LEAD',
  JOB_RECRUITER: 'JOB_RECRUITER',
  // job hired filters
  JOB_HIRED_ONLY_MY: 'JOB_HIRED_ONLY_MY',
  JOB_HIRED_ACTION: 'JOB_HIRED_ACTION',
  // job approvers
  EDIT_APPROVERS: 'EDIT_APPROVERS',
  // opening creation form
  OPENING_TEAM_LEAD: 'OPENING_TEAM_LEAD',
  OPENING_PRIORITY: 'OPENING_PRIORITY',
  OPENING_EXPECTED_DATE: 'OPENING_EXPECTED_DATE',
  OPENING_OFFICE_LOCATIONS: 'OPENING_OFFICE_LOCATIONS',
  OPENING_FOREIGN_LANGUAGES: 'OPENING_FOREIGN_LANGUAGES',
  OPENING_EMPLOYMENT_TYPE: 'OPENING_EMPLOYMENT_TYPE',
  OPENING_WORK_EXPERIENCE: 'OPENING_WORK_EXPERIENCE',
  OPENING_EXPERIENCE_LEVEL: 'OPENING_EXPERIENCE_LEVEL',
  OPENING_RESPONSIBILITIES: 'OPENING_RESPONSIBILITIES',
  OPENING_COMMENT: 'OPENING_COMMENT',
  CLONE_OPENING: 'CLONE_OPENING',
  // feedback creation form
  FEEDBACK_ENGLISH_LEVEL: 'FEEDBACK_ENGLISH_LEVEL',
  FEEDBACK_EXPERIENCE_LEVEL: 'FEEDBACK_EXPERIENCE_LEVEL',
  // candidate profile
  SHARE_PROFILE: 'SHARE_PROFILE',
  // resolution
  EDIT_RESOLUTION: 'EDIT_RESOLUTION',
};

export const APPROVER_ROLES = [
  {
    value: `${ROLES.RECRUITER},${ROLES.HR_LEAD},${ROLES.TEAM_LEAD},${ROLES.SERVICE_HEAD},${ROLES.RESOURCE_MANAGER}`,
    label: 'All Roles',
  },
  { value: ROLES.RECRUITER, label: 'Recruiters' },
  { value: ROLES.HR_LEAD, label: 'HR Leads' },
  { value: ROLES.TEAM_LEAD, label: 'Team Leads' },
  { value: ROLES.SERVICE_HEAD, label: ROLES.SERVICE_HEAD },
  { value: ROLES.RESOURCE_MANAGER, label: ROLES.RESOURCE_MANAGER },
];

export const APPROVER_ROLES_WITH_INTERVIEWER = [
  {
    value: `${ROLES.INTERVIEWER},${ROLES.RECRUITER},${ROLES.HR_LEAD},${ROLES.TEAM_LEAD},${ROLES.RESOURCE_MANAGER}`,
    label: 'All Roles',
  },
  { value: ROLES.INTERVIEWER, label: ROLES.INTERVIEWER },
  { value: ROLES.RECRUITER, label: ROLES.RECRUITER },
  { value: ROLES.HR_LEAD, label: ROLES.HR_LEAD },
  { value: ROLES.TEAM_LEAD, label: ROLES.TEAM_LEAD },
  { value: ROLES.SERVICE_HEAD, label: ROLES.SERVICE_HEAD },
  { value: ROLES.RESOURCE_MANAGER, label: ROLES.RESOURCE_MANAGER },
];

export const APPROVERS_ROLES = [
  ROLES.HR_LEAD,
  ROLES.RECRUITER,
  ROLES.RECRUITER_LEAD,
  ROLES.RESOURCE_MANAGER,
  ROLES.TEAM_LEAD,
  ROLES.LOCATION_MANAGER,
  ROLES.INTERVIEWER,
  ROLES.SERVICE_HEAD,
];
