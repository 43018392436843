import React from 'react';
import { Col, Form, Radio, Row } from 'antd';
import { useSelector } from 'react-redux';
import { Button, BUTTON_TYPES, Input, Select } from '_atoms';
import { REQUIRED_RULE, SOURCE_RULE } from '_organisms/InterviewFeedback/constants';
import { getCandidateSources } from 'store/selectors/candidateSelectors';
import { ReactComponent as Union } from 'dist/icons/Union.svg';
import { ReactComponent as Bin } from 'dist/icons/bin.svg';
import './style.scss';

export const FormListSources = ({ firstSelectValidator, maxCount = 5 }) => {
  const form = Form.useFormInstance();

  const sourcesOptions = useSelector(getCandidateSources);

  const onChangeType = key => {
    const fields = form.getFieldsValue();
    const { sources } = fields;
    Object.assign(sources[key], { additional_source_name: '' });
    form.setFieldsValue({ sources });
  };

  const onChangeMain = key => {
    const fields = form.getFieldsValue();
    const { sources } = fields;
    const sourcesUpdated = sources.map((s, idx) => (key !== idx ? { ...s, is_main: false } : { ...s, is_main: true }));
    form.setFieldsValue({ sources: sourcesUpdated });
  };

  return (
    <Form.List name="sources">
      {(fields, { add, remove }) => (
        <div className="form-list-sources">
          {fields.map((field, index) => (
            <Row key={field.key} className="form-list-sources__item" gutter={{ lg: 32, md: 32, sm: 16 }}>
              <Col lg={12} md={12} sm={24} className="form-list-sources__item__first">
                <Select
                  allowClear
                  required
                  label={!index && 'Source'}
                  itemProps={{
                    ...field,
                    name: [field.name, 'source'],
                    fieldKey: [field.fieldKey, 'source'],
                    rules: [REQUIRED_RULE],
                  }}
                  options={sourcesOptions}
                  onChange={() => onChangeType(field.name)}
                  placeholder="Select source"
                />
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) => prev.sources[field.name]?.source !== curr.sources[field.name]?.source}
                >
                  {({ getFieldValue }) =>
                    ['recommendations', 'other'].includes(getFieldValue('sources')[field.name]?.source) && (
                      <Input
                        itemProps={{
                          ...field,
                          name: [field.name, 'additional_source_name'],
                          fieldKey: [field.fieldKey, 'additional_source_name'],
                          rules: [SOURCE_RULE],
                        }}
                        placeholder="Enter source"
                        maxLength={32}
                        // eslint-disable-next-line no-return-assign
                        onInput={e => (e.target.value = e.target.value.toLowerCase())}
                      />
                    )
                  }
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} className="form-list-sources__item__second">
                <div>
                  <Row align="middle" gutter={{ lg: 20, md: 20, sm: 0 }}>
                    <Col flex="1 1 0">
                      <Form.Item
                        {...field}
                        name={[field.name, 'is_main']}
                        fieldKey={[field.fieldKey, 'is_main']}
                        rules={firstSelectValidator ? [f => firstSelectValidator(f, field.name)] : undefined}
                        valuePropName="checked"
                      >
                        <Radio onChange={() => onChangeMain(field.name)}>Main Source</Radio>
                      </Form.Item>
                    </Col>
                    {!!index && (
                      <Col>
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue }) => (
                            <Button
                              className="form-list-sources__item__delete"
                              type={BUTTON_TYPES.GHOST}
                              onClick={() => {
                                if (getFieldValue('sources')[field.name]?.is_main) onChangeMain(0);
                                remove(field.name);
                              }}
                              icon={<Bin />}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          ))}
          {fields.length < maxCount && (
            <Button icon={<Union />} onClick={() => add()}>
              Add Source
            </Button>
          )}
        </div>
      )}
    </Form.List>
  );
};
