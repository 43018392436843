import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Form, Row } from 'antd';
import { Checkbox, TextArea, Select, DatePicker, Popup, Tooltip } from '_atoms';
import { REQUIRED_RULE } from '_organisms/InterviewFeedback/constants';
import { ACTIONS, Can, ROLES, UI } from 'permission';
import { possibleTime } from 'constants/constants';
import { BUTTON_TEXT } from 'constants/text';
import * as actions from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import './style.scss';

const reminderType = [
  { value: 'Phone call', label: 'Phone call' },
  { value: 'Skype', label: 'Skype' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Email', label: 'Email' },
  { value: 'Telegram', label: 'Telegram' },
  { value: 'Viber', label: 'Viber' },
  { value: 'Interview', label: 'Interview' },
  { value: 'Other interaction', label: 'Other interaction' },
];
export const PopupCreateReminder = ({ open, onClose, data, initials }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  const user = useSelector(getUser);
  const currentUserIsRecruiter =
    user.active_role.name === ROLES.RECRUITER || user.active_role.name === ROLES.RECRUITER_LEAD;

  const [recruiterOptions, setRecruiterOptions] = useState([]);

  const initialValues = useMemo(
    () => ({
      date: moment(),
      time: possibleTime[26].value,
      candidate: initials,
      comment: '',
      type: undefined,
      create_google_event: false,
      recruiter: currentUserIsRecruiter ? user.uuid : undefined,
    }),
    [initials, currentUserIsRecruiter, user]
  );

  const onSave = ({ date, time, ...values }) => {
    const hour = time.split(':')[0];
    const minute = time.split(':')[1];
    dispatch(
      actions.editReminder(
        {
          ...values,
          datetime: date.set({ hour, minute, second: 0 }),
          candidate: id,
          in_progress: true,
        },
        data?.uuid,
        id
      )
    ).then(onClose);
  };

  useEffect(() => {
    if (open) {
      actions
        .getListUsersByRole({ role: [ROLES.HR_LEAD, ROLES.RECRUITER_LEAD, ROLES.RECRUITER] })
        .then(resp =>
          setRecruiterOptions(
            resp.map(r => ({ label: r.full_name, value: r.uuid, has_calendar_access: r.profile.has_calendar_access }))
          )
        )
        .catch(() => setRecruiterOptions([]));
    } else {
      setRecruiterOptions([]);
      form.resetFields();
    }
  }, [open]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (data) form.setFieldsValue(data);
  }, [data]);

  return (
    <Popup
      open={open}
      title={data ? 'Edit reminder' : 'New reminder'}
      cancelButtonProps={{
        onClick: onClose,
        children: BUTTON_TEXT.CANCEL,
      }}
      okButtonProps={{
        children: data ? BUTTON_TEXT.SAVE : BUTTON_TEXT.ADD,
        form: 'form-reminder',
        htmlType: 'submit',
      }}
      className="popup-new-reminder"
    >
      <Form name="form-reminder" form={form} initialValues={initialValues} onFinish={onSave}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <DatePicker
              label="Date"
              placeholder="Please select date"
              itemProps={{ name: 'date', rules: [REQUIRED_RULE] }}
              minDate={moment().startOf('day')}
            />
          </Col>
          <Col span={12}>
            <Select
              label="Time"
              placeholder="Expected date"
              options={possibleTime}
              itemProps={{ name: 'time', rules: [REQUIRED_RULE] }}
            />
          </Col>
        </Row>
        <Select
          label="Reminder type"
          placeholder="Please select reminder type"
          options={reminderType}
          itemProps={{ name: 'type', rules: [REQUIRED_RULE] }}
        />
        <Can I={ACTIONS.READ} a={UI.EDIT_RECRUITER_REMINDER} passThrough>
          {access => (
            <Select
              label={ROLES.RECRUITER}
              placeholder="Please select Recruiter"
              options={recruiterOptions}
              disabled={!access}
              itemProps={{ name: 'recruiter', rules: [REQUIRED_RULE] }}
              labelInValue={false}
              optionFilterProp="label"
              onChange={v => {
                form.setFieldValue('create_google_event', v.has_calendar_access);
              }}
            />
          )}
        </Can>
        <Select
          label="Candidate"
          placeholder="Please select candidate"
          options={[]}
          disabled
          itemProps={{ name: 'candidate', rules: [REQUIRED_RULE] }}
        />
        <TextArea label="Comment" placeholder="Please enter your comment" itemProps={{ name: 'comment' }} />
        <Form.Item shouldUpdate={(prev, curr) => prev.recruiter !== curr.recruiter} noStyle>
          {({ getFieldValue }) => {
            const disabled = !recruiterOptions.find(r => r.value === getFieldValue('recruiter'))?.has_calendar_access;
            return (
              <div className="reminder-calendar-checker">
                <Checkbox
                  disabled={disabled}
                  label="Add reminder to calendar"
                  itemProps={{ name: 'create_google_event' }}
                />
                {disabled && (
                  <Tooltip label="This feature is not available because the system does not have access to the Google calendar of the selected user">
                    <Info />
                  </Tooltip>
                )}
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </Popup>
  );
};
