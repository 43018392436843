import React from 'react';
import { ActiveOpenings } from '_organisms';
import { MyActiveCandidates } from '../DashboardHRLead/DashboardHRLead';
import './style.scss';

export const DashboardTeamLead = () => (
  <div className="dashboard-team-lead">
    <div className="dashboard-team-lead__banner-wrapper">
      <div className="top-banner" />
      <ActiveOpenings />
    </div>
    <MyActiveCandidates setTabsLoading={() => {}} />
  </div>
);
