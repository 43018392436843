import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { Col, Form, Row } from 'antd';
import {
  Popup,
  AtsButtonMenu,
  Typography,
  TYPOGRAPHY_WEIGHT,
  TimelineItem,
  Card,
  SelectSmall,
  PLACEMENTS,
  Button,
  BUTTON_TYPES,
  Divider,
  RichArea,
  Select,
} from '_atoms';
import { PopupCompleteReminder, PopupCreateInteraction, PopupCreateReminder } from '_molecules';
import { ACTIONS, Can, UI } from 'permission';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getCandidateReminders, getCandidateTimeline } from 'store/selectors/candidateSelectors';
import { BUTTON_TEXT } from 'constants/text';
import {
  completeReminder,
  deleteInteraction,
  deleteReminder,
  getMentionUsers,
  getReminders,
  getTimeline,
  postCommentTimeline,
} from 'actions';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import { setCandidateTimeline, setCandidateTimelineFilters, setRemindersList } from 'store/reducers/candidateReducer';
import { getJobTimelineCommentLoading, getTimelineLoading } from 'store/selectors/jobSelectors';
import './style.scss';

const SORTING_OPTIONS = [
  { value: 'desc', label: 'Newest first' },
  { value: 'asc', label: 'Oldest first' },
];

const FILTER_OPTIONS = [
  { value: '', label: 'All activities' },
  { value: 'interactions', label: 'Interactions' },
  { value: 'job_updates', label: 'Job updates' },
  { value: 'profile', label: 'Profile' },
  { value: 'status', label: 'Status' },
];

const initialValues = {
  sorting: SORTING_OPTIONS[0],
  filter: FILTER_OPTIONS[0],
};
const initialValuesFeed = {
  sorting: SORTING_OPTIONS[0],
};

const SortingButtonInput = ({ value, onChange, options, ...props }) => (
  <button
    className="sorting-button"
    type="button"
    onClick={() => onChange(options[value.value === options[0].value ? 1 : 0])}
    {...props}
  >
    {value.label}
    <Sort className={value.value} />
  </button>
);
const SortingButton = ({ itemProps, ...props }) => (
  <Form.Item {...itemProps}>
    <SortingButtonInput {...props} />
  </Form.Item>
);

export const Timeline = ({ candidateData, isActivityFeed, hrManagers }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { id, candidate } = useParams();

  const initials = useMemo(() => `${candidateData.first_name_latin} ${candidateData.last_name_latin}`, [candidateData]);

  const [form] = Form.useForm();

  const timeline = useSelector(getCandidateTimeline);
  const reminders = useSelector(getCandidateReminders);
  const loading = useSelector(getTimelineLoading);

  const [openReminderModal, setOpenReminderModal] = useState(false);
  const [reminderData, setReminderData] = useState(null);
  const [newInteractionModal, openInteractionModal] = useState(false);
  const [dataInteraction, changeDataInteraction] = useState(null);
  const [deleteInteractionUuid, setDeleteInteractionUuid] = useState(null);

  const closeInteractionPopup = () => {
    openInteractionModal(false);
    changeDataInteraction(null);
  };

  const closeReminderPopup = () => {
    setOpenReminderModal(false);
    setReminderData(null);
  };

  const onNewInteraction = () => {
    openInteractionModal(true);
  };

  const onActionClick = (type, { details }) => {
    switch (type) {
      case 'interaction-edit':
        changeDataInteraction({
          uuid: details.uuid,
          date: moment(details.datetime),
          time: moment(details.datetime).format('HH:mm'),
          type: details.type,
          recruiter: details.recruiter.uuid,
          comment: details.comment,
        });
        openInteractionModal(true);
        break;
      case 'interaction-delete':
        setDeleteInteractionUuid(details.uuid);
    }
  };

  const hasMore = timeline.count > timeline.data.length;

  const onLoadMore = clear => dispatch(getTimeline(candidate || id, candidate ? id : candidate, clear));

  const onChangeFilters = (_, values) => {
    const sorting = values.sorting.value;
    const filter = values.filter?.value;
    dispatch(setCandidateTimelineFilters({ filter, sorting, offset: 0 }));
    onLoadMore(true);
  };

  useEffect(() => {
    dispatch(
      setCandidateTimelineFilters({ sorting: SORTING_OPTIONS[0].value, filter: FILTER_OPTIONS[0].value, offset: 0 })
    );
    form.resetFields();
    if (!candidate) dispatch(getReminders(id));
    onLoadMore(true);
    return () => {
      dispatch(setRemindersList([]));
      dispatch(setCandidateTimeline({ clear: true }));
      dispatch(
        setCandidateTimelineFilters({ sorting: SORTING_OPTIONS[0].value, filter: FILTER_OPTIONS[0].value, offset: 0 })
      );
    };
  }, [id, candidate]);

  return (
    <Card className={cn('timeline', { 'activity-feed': isActivityFeed })}>
      <div className="timeline__header">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD} className="title">
          {isActivityFeed ? 'Activity feed' : 'Timeline'}
          <span>{timeline.count + reminders.length}</span>
        </Typography.Title2>
        <div className="actions">
          <Form
            name="timeline"
            form={form}
            initialValues={isActivityFeed ? initialValuesFeed : initialValues}
            onValuesChange={onChangeFilters}
          >
            <SortingButton itemProps={{ name: 'sorting' }} options={SORTING_OPTIONS} />
            {!isActivityFeed && (
              <>
                <Divider type="vertical" />
                <SelectSmall
                  label="Show"
                  itemProps={{ name: 'filter' }}
                  options={FILTER_OPTIONS}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </>
            )}
          </Form>
          <Can I={ACTIONS.READ} a={UI.EDIT_CANDIDATE_TIMELINE} passThrough>
            {access =>
              access &&
              !isActivityFeed && (
                <AtsButtonMenu>
                  <li role="none" onClick={onNewInteraction}>
                    New interaction
                  </li>
                  <li role="none" onClick={() => setOpenReminderModal(true)}>
                    New reminder
                  </li>
                </AtsButtonMenu>
              )
            }
          </Can>
        </div>
      </div>
      <div className="timeline__body">
        {isActivityFeed ? (
          <TimelineComment />
        ) : (
          <Reminders
            initials={initials}
            reminderData={reminderData}
            setReminderData={setReminderData}
            setOpenReminderModal={setOpenReminderModal}
          />
        )}
        {timeline.data.map((data, k) => (
          <TimelineItem key={`${k + 1}`} data={data} onAction={onActionClick} />
        ))}
        {hasMore && (
          <Button
            className="timeline__load-more"
            type={BUTTON_TYPES.TEXT}
            onClick={() => onLoadMore(false)}
            disabled={loading}
          >
            Load more
          </Button>
        )}
      </div>
      <PopupCreateInteraction open={newInteractionModal} onClose={closeInteractionPopup} data={dataInteraction} />
      <PopupCreateReminder
        open={openReminderModal}
        onClose={closeReminderPopup}
        data={reminderData}
        initials={initials}
      />
      <Popup
        open={deleteInteractionUuid}
        title="Delete interaction"
        cancelButtonProps={{ onClick: () => setDeleteInteractionUuid(null), children: BUTTON_TEXT.NO }}
        okButtonProps={{
          onClick: () => {
            dispatch(deleteInteraction(deleteInteractionUuid, id));
            setDeleteInteractionUuid(null);
          },
          children: BUTTON_TEXT.YES,
        }}
      >
        Are you sure you want to delete interaction?
      </Popup>
    </Card>
  );
};

const Reminders = ({ initials, reminderData, setReminderData, setOpenReminderModal }) => {
  const dispatch = useDispatch();
  const { id, candidate } = useParams();
  const candidateId = candidate || id;

  const reminders = useSelector(getCandidateReminders);

  const [isAllReminders, setIsAllReminders] = useState(false);

  const [openComplete, setOpenComplete] = useState(false);
  const [deleteReminderData, setDeleteReminderData] = useState(null);

  const remindersToShow = useMemo(() => (isAllReminders ? reminders : [reminders[0]]), [isAllReminders, reminders]);

  const onClickAllReminders = () => setIsAllReminders(a => !a);

  const onActionClick = (type, data) => {
    switch (type) {
      case 'reminder-edit':
        setReminderData({
          date: moment(data.datetime),
          time: moment(data.datetime).format('HH:mm'),
          candidate: initials,
          comment: data.comment,
          type: data.type,
          uuid: data.uuid,
          create_google_event: data.create_google_event,
          recruiter: data.recruiter.uuid,
        });
        setOpenReminderModal(true);
        break;
      case 'reminder-complete':
        setReminderData(data);
        setOpenComplete(true);
        break;
      case 'reminder-delete':
        setDeleteReminderData(data);
    }
  };

  if (!reminders.length) return null;

  return (
    <div className="reminders">
      <Typography.Title3 className="reminders__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
        {`Reminder${reminders.length > 1 ? 's' : ''}`}
        <span>({reminders.length})</span>
      </Typography.Title3>
      {remindersToShow.map(data => (
        <TimelineItem key={data.uuid} data={data} onAction={onActionClick} />
      ))}
      {reminders.length > 1 && (
        <Button type={BUTTON_TYPES.TEXT} className="reminders__see-all" onClick={onClickAllReminders}>
          {`Show ${isAllReminders ? 'less' : 'more'} reminders `}
        </Button>
      )}
      <PopupCompleteReminder
        open={openComplete}
        onSave={comment => {
          dispatch(completeReminder(reminderData, candidateId, comment));
          setOpenComplete(false);
          setReminderData(null);
        }}
        onClose={() => {
          setOpenComplete(false);
          setReminderData(null);
        }}
      />
      <Popup
        open={deleteReminderData}
        title="Delete reminder"
        cancelButtonProps={{ onClick: () => setDeleteReminderData(null), children: BUTTON_TEXT.CANCEL }}
        okButtonProps={{
          onClick: () => {
            setDeleteReminderData(null);
            dispatch(deleteReminder(deleteReminderData.uuid, candidateId));
          },
          children: BUTTON_TEXT.DELETE,
        }}
      >
        Do you really want to delete reminder?
      </Popup>
    </div>
  );
};

const TimelineComment = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id: jobUuid, candidate: candidateUuid } = useParams();

  const loading = useSelector(getJobTimelineCommentLoading);

  const [mentionUsersOptions, setMentionUsersOptions] = useState([]);
  const [initialComment, setInitialComment] = useState('');
  const [comment, setComment] = useState('');

  const loadUsers = () => {
    setMentionUsersOptions([]);
    getMentionUsers(jobUuid, candidateUuid).then(users => {
      setMentionUsersOptions(users);
    });
  };

  const onPostComment = ({ mention_users }) => {
    dispatch(postCommentTimeline(comment, mention_users?.map(({ value }) => value) || [], jobUuid, candidateUuid)).then(
      () => {
        setInitialComment(' '); // crutch because the input field is not controlled
        setInitialComment('');
        form.resetFields();
      }
    );
  };

  return (
    <div>
      <Form className="timeline__comment" name="timeline-comment" form={form} onFinish={onPostComment}>
        <Row gutter={[32, 0]}>
          <Col lg={12} md={12} sm={24}>
            <Select
              labelInValue
              label="Mention user(s)"
              mode={SELECT_TYPES.MULTIPLE}
              options={mentionUsersOptions}
              onDropdownVisibleChange={open => {
                if (open) loadUsers();
              }}
              fieldNames={{ value: 'uuid', label: 'full_name' }}
              itemProps={{ name: 'mention_users' }}
            />
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col lg={24} md={24} sm={24}>
            <RichArea
              label="Comment"
              maxLength={6000}
              placeholder="Add a comment"
              onChange={setComment}
              initialValue={initialComment}
            />
          </Col>
        </Row>
        <Button
          htmlType="submit"
          type={BUTTON_TYPES.SECONDARY}
          // onClick={onPostComment}
          disabled={!comment}
          loading={loading}
        >
          {BUTTON_TEXT.POST_COMMENT}
        </Button>
      </Form>
    </div>
  );
};
