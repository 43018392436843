import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Card, Input, Select, Button, BUTTON_TYPES, RangePicker } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { getCandidateSources } from 'store/selectors/candidateSelectors';
import { getCandidateSourcesAPI } from 'actions';
import { ACTIONS, Can, UI, useAbility } from 'permission';
import { fetchWrapper } from 'helpers/helpers';
import { useDebounceEffect } from 'helpers/useDebounceEffect';
import { BUTTON_TEXT } from 'constants/text';
import { ReactComponent as Arrow } from 'dist/icons/Vector1.svg';
import { LABELS, CANDIDATE_STATUSES, STEPS_OPTIONS, OPTIONS_REQUEST_CONFIG, EXPERIENCE_LEVEL } from './constants';
import { optionsMappers } from './utils';
import './style.scss';

const FiltersCandidate = ({ form, initialValues, onFinish, onReset, loading }) => {
  const dispatch = useDispatch();
  const ability = useAbility();
  const showMoreAccess = ability.can(ACTIONS.READ, UI.CANDIDATE_LIST_DISPLAY_SHOW_MORE);
  const accessToSources = ability.can(ACTIONS.READ, UI.CANDIDATE_LIST_SOURCE);
  const [showExtraFilters, setShowExtraFilters] = useState(!showMoreAccess);
  const [filtersLoading, setFiltersLoading] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [openDetails, onOpenDetails] = useState(false);

  const sourcesOptions = useSelector(getCandidateSources);

  const onShowMore = () => setShowExtraFilters(s => !s);
  const onSearchDebounce = (type, value = '') => {
    if (filtersLoading[type]) return;
    setFiltersLoading(f => ({ ...f, [type]: true }));
    fetchWrapper(OPTIONS_REQUEST_CONFIG[type](value))
      .then(options => {
        setFilterOptions(o => ({ ...o, [type]: optionsMappers[type](options.results, value) }));
        setFiltersLoading(f => ({ ...f, [type]: false }));
      })
      .catch(() => {
        setFilterOptions(o => ({ ...o, [type]: [] }));
        setFiltersLoading(f => ({ ...f, [type]: false }));
      });
  };
  const onSearch = useDebounceEffect(onSearchDebounce, 300);

  const onBeforeFinish = values => {
    onFinish({ ...initialValues, ...values });
  };

  const typeToSearch = () => onBeforeFinish(form.getFieldsValue());

  const onChangeCountry = countries => {
    form.setFieldValue('city', []);
    const citiesOptions = countries.map(c => optionsMappers.city(c.cities)).flat();
    setFilterOptions(o => ({ ...o, city: citiesOptions }));
  };

  useEffect(() => {
    if (accessToSources) dispatch(getCandidateSourcesAPI());
  }, [accessToSources]);

  return (
    <Card className={cn('candidates-filters', { open: openDetails })}>
      <div className="candidates-filters__header">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Filters</Typography.Title2>
        <Button
          className={cn('open-details original-svg', { open: openDetails })}
          onClick={() => onOpenDetails(o => !o)}
          type={BUTTON_TYPES.GHOST}
          icon={<Arrow />}
        />
      </div>
      <div className="candidates-filters__body">
        <Form form={form} onFinish={onBeforeFinish} initialValues={initialValues}>
          <Row gutter={[32, 0]}>
            <Col span={24}>
              <Input
                placeholder="Search by candidate name"
                type="search"
                onDebounceChange={loading ? undefined : typeToSearch}
                itemProps={{ name: 'search' }}
              />
            </Col>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_SERVICE} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.SERVICE}
                    options={filterOptions.service}
                    loading={filtersLoading.service}
                    onSearch={v => onSearch('service', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('service')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'service' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_TEAM_LEAD} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.TEAM_LEAD}
                    options={filterOptions.team_lead}
                    loading={filtersLoading.team_lead}
                    onSearch={v => onSearch('team_lead', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('team_lead')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'team_lead' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_JOB} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.JOB}
                    options={filterOptions.jobs}
                    loading={filtersLoading.jobs}
                    onSearch={v => onSearch('jobs', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('jobs')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'jobs' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_JOB_STEP} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.JOB_STEP}
                    options={STEPS_OPTIONS}
                    mode={SELECT_TYPES.MULTIPLE}
                    itemProps={{ name: 'job_step' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_CANDIDATE_STATUS} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.CANDIDATE_STATUS}
                    options={CANDIDATE_STATUSES}
                    mode={SELECT_TYPES.MULTIPLE}
                    itemProps={{ name: 'status' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_SPECIALTY} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.SPECIALITY}
                    options={filterOptions.speciality}
                    loading={filtersLoading.speciality}
                    onSearch={v => onSearch('speciality', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('speciality')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'speciality' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_TAGS} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.TAGS}
                    options={filterOptions.tags}
                    loading={filtersLoading.tags}
                    onSearch={v => onSearch('tags', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('tags')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'tags' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_COMPANIES} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <Select
                    labelInValue
                    label={LABELS.COMPANIES}
                    options={filterOptions.companies}
                    loading={filtersLoading.companies}
                    onSearch={v => onSearch('companies', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('companies')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'companies' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_COUNTRY} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <Select
                    labelInValue
                    label={LABELS.COUNTRY}
                    options={filterOptions.country}
                    loading={filtersLoading.country}
                    onSearch={v => onSearch('country', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('country')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'country' }}
                    onChange={onChangeCountry}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_CITY} passThrough>
              {access => (
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.country !== curr.country}>
                  {({ getFieldValue }) =>
                    !!getFieldValue('country').length && (
                      <Col
                        span={24}
                        lg={24}
                        md={12}
                        sm={24}
                        className={cn({ hidden_field: !access || !showExtraFilters })}
                      >
                        <Select
                          labelInValue
                          label={LABELS.CITY}
                          options={filterOptions.city}
                          mode={SELECT_TYPES.MULTIPLE}
                          itemProps={{ name: 'city' }}
                        />
                      </Col>
                    )
                  }
                </Form.Item>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_RESPONSIBLE_RECRUITER} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <Select
                    labelInValue
                    label={LABELS.RESPONSIBLE_RECRUITER}
                    options={filterOptions.responsible_recruiters}
                    loading={filtersLoading.responsible_recruiters}
                    onSearch={v => onSearch('responsible_recruiters', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('responsible_recruiters')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'responsible_recruiters' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_INTERACTED_BY} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <Select
                    labelInValue
                    label={LABELS.INTERACTED_BY}
                    options={filterOptions.interacted_by}
                    loading={filtersLoading.interacted_by}
                    onSearch={v => onSearch('interacted_by', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('interacted_by')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'interacted_by' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_SOURCE} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <Select
                    labelInValue
                    label={LABELS.SOURCE}
                    options={sourcesOptions}
                    mode={SELECT_TYPES.MULTIPLE}
                    itemProps={{ name: 'sources' }}
                    placeholder="Select source"
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_LAST_INTERACTION} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <RangePicker label={LABELS.LAST_INTERACTION} itemProps={{ name: 'last_interaction' }} />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_INTERVIEWED_BY} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <Select
                    labelInValue
                    label={LABELS.INTERVIEWED_BY}
                    options={filterOptions.interviewed_by}
                    loading={filtersLoading.interviewed_by}
                    onSearch={v => onSearch('interviewed_by', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('interviewed_by')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'interviewed_by' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_INTERVIEWED_DATE} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <RangePicker label={LABELS.INTERVIEW_DATE} itemProps={{ name: 'interview_date' }} />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_EXPERIENCE_LEVEL} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.EXPERIENCE_LEVEL}
                    options={EXPERIENCE_LEVEL.slice(0, -1)}
                    mode={SELECT_TYPES.MULTIPLE}
                    itemProps={{ name: 'experience_level' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_ADDED_BY} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <Select
                    labelInValue
                    label={LABELS.ADDED_BY}
                    options={filterOptions.added_by}
                    loading={filtersLoading.added_by}
                    onSearch={v => onSearch('added_by', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('added_by')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'added_by' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_ADDED_DURING_PERIOD} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access || !showExtraFilters })}>
                  <RangePicker label={LABELS.ADDED_DURING} itemProps={{ name: 'added_during_date' }} />
                </Col>
              )}
            </Can>
          </Row>
          <Can I={ACTIONS.READ} a={UI.CANDIDATE_LIST_DISPLAY_SHOW_MORE}>
            <Button type={BUTTON_TYPES.TEXT} className="candidates-filters__show-more" onClick={onShowMore}>
              {showExtraFilters ? 'show less' : 'show more'}
            </Button>
          </Can>
          <div className="candidates-filters__footer">
            <Button type={BUTTON_TYPES.SECONDARY} onClick={loading ? undefined : onReset}>
              {BUTTON_TEXT.RESET_FILTERS}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} htmlType={loading ? 'button' : 'submit'}>
              {BUTTON_TEXT.APPLY}
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default FiltersCandidate;
