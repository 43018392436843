import React, { useState } from 'react';
import { Tabs } from '_atoms';
import { NewJobs, SelfSubmittedCandidatesDashboard } from '_organisms';
import { useQuery } from 'helpers/useLocation';
import { MyActiveCandidates } from '../DashboardHRLead/DashboardHRLead';
import { TABS } from './constants';
import './style.scss';

export const DashboardRecruiterLead = () => {
  const query = useQuery();

  const [tab, setTab] = useState(query.tab || TABS[0].key);

  return (
    <div className="dashboard-recruiter-lead">
      <div className="dashboard-recruiter-lead__banner-wrapper">
        <div className="top-banner" />
        <NewJobs />
      </div>
      <Tabs className="dashboard-recruiter-lead__tabs" activeTab={tab} onChange={setTab} items={TABS} />
      {
        {
          [TABS[0].key]: <MyActiveCandidates leadDashboard setTabsLoading={() => {}} />,
          [TABS[1].key]: <SelfSubmittedCandidatesDashboard dashboard="recruiter-lead" />,
        }[tab]
      }
    </div>
  );
};
