import { fetchWrapper } from 'helpers/helpers';

export const getSAJobsTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/super-admin/openings/jobs/',
    version: 'v2',
    body,
  });

export const getSAServicesTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/super-admin/openings/services/',
    version: 'v2',
    body,
  });

export const getHRLJobsDashboardAPI = () =>
  fetchWrapper({
    url: 'dashboard/hr-lead/jobs/',
    version: 'v2',
  });

export const getHRLJobsTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/hr-lead/openings/jobs/',
    version: 'v2',
    body,
  });

export const getHRLServicesTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/hr-lead/openings/services/',
    version: 'v2',
    body,
  });

export const getCandidatesTableDashboardAPI = (dashboard, body, grouping = '') =>
  fetchWrapper({
    url: `dashboard/${dashboard}/candidates/${grouping && `${grouping}/`}`,
    version: 'v2',
    body,
  });

export const getRecruiterLeadJobsDashboardAPI = () =>
  fetchWrapper({
    url: 'dashboard/recruiter-lead/jobs/',
    version: 'v2',
  });

export const getRecruiterLeadCandidatesTableDashboardAPI = (body, grouping = '') =>
  fetchWrapper({
    url: `dashboard/recruiter-lead/candidates/${grouping && `${grouping}/`}`,
    version: 'v2',
    body,
  });

export const getRecruiterCandidatesTableDashboardAPI = (body, grouping = '') =>
  fetchWrapper({
    url: `dashboard/recruiter/candidates/${grouping && `${grouping}/`}`,
    version: 'v2',
    body,
  });

export const getRecruiterSSCTableDashboardAPI = (dashboard, body) =>
  fetchWrapper({
    url: `dashboard/${dashboard}/self-submitted-candidates/`,
    version: 'v2',
    body,
  });

export const getRMServicesTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/resource-manager/openings/services/',
    version: 'v2',
    body,
  });

export const getRMJobsTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/resource-manager/openings/jobs/',
    version: 'v2',
    body,
  });

export const getTLActiveOpeningsDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/team-lead/openings/',
    version: 'v2',
    body,
  });

export const getTLCandidatesTableDashboardAPI = (body, grouping = '') =>
  fetchWrapper({
    url: `dashboard/team-lead/candidates/${grouping && `${grouping}/`}`,
    version: 'v2',
    body,
  });

export const getLMOpeningsTableDashboardAPI = (body, grouping) =>
  fetchWrapper({
    url: `dashboard/location-manager/openings/${grouping}/`,
    version: 'v2',
    body,
  });

export const getSHServicesTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/service-head/openings/services/',
    version: 'v2',
    body,
  });

export const getSHJobsTableDashboardAPI = body =>
  fetchWrapper({
    url: 'dashboard/service-head/openings/jobs/',
    version: 'v2',
    body,
  });

export const getSHCandidatesTableDashboardAPI = (body, grouping = '') =>
  fetchWrapper({
    url: `dashboard/service-head/candidates/${grouping && `${grouping}/`}`,
    version: 'v2',
    body,
  });

export const getInterviewerCandidatesTableDashboardAPI = (body, grouping = '') =>
  fetchWrapper({
    url: `dashboard/interviewer/candidates/${grouping && `${grouping}/`}`,
    version: 'v2',
    body,
  });
