import moment from 'moment';
import { dateFormatter } from 'helpers/dateFormatter';
import { STEPS_LABELS, stepsStatuses } from 'constants/constants';
import { OPENING_STATUSES } from 'constants/opening';
import { BUTTON_LABELS, CANDIDATES_GROUP, OPENINGS_GROUP } from './constants';

export const useTabs = disabled => [
  {
    label: 'Openings',
    key: 'openings',
    disabled,
  },
  {
    label: 'Active candidates',
    key: 'candidates',
    disabled,
  },
  {
    label: 'Self-submitted candidates',
    key: 'ssc',
    disabled,
  },
];

export const isServiceGrouping = grouping => grouping === OPENINGS_GROUP[0].value;

export const serviceTableDataMapper = services =>
  services.map(service => ({
    uuid: service.uuid,
    name: service.name,
    count: service.openings_count,
    candidates_count: service.candidates_count,
    openings_count: service.total_openings_count,
    openings: service.openings.map(opening => ({
      uuid: opening.uuid,
      job: {
        job_name: opening.job_name,
        job_uuid: opening.job_uuid,
        priority: opening.priority,
      },
      level: opening.custom_level || opening.level,
      creation_date: dateFormatter(opening.created),
      expected_date: {
        date: dateFormatter(opening.expected),
        error:
          opening.status === OPENING_STATUSES.OPEN &&
          moment(opening.expected, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day')),
      },
      rm_approval: opening.rm_approval_date ? dateFormatter(opening.rm_approval_date) : '-',
      team_lead: opening.team_lead.full_name,
      status: opening.status,
      action: {
        status: opening.status,
        job_uuid: opening.job_uuid,
        job_name: opening.job_name,
        service_uuid: opening.uuid,
        opening,
      },
    })),
  }));

export const jobTableDataMapper = jobs =>
  jobs.map(job => ({
    uuid: job.uuid,
    name: job.name,
    count: job.openings_count,
    service: job.service_name,
    candidates_count: job.candidates_count,
    openings_count: job.total_openings_count,
    openings: job.openings.map(opening => ({
      uuid: opening.uuid,
      level: { priority: opening.priority, level: opening.custom_level || opening.level },
      creation_date: dateFormatter(opening.created),
      expected_date: {
        date: dateFormatter(opening.expected),
        error:
          opening.status === OPENING_STATUSES.OPEN &&
          moment(opening.expected, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day')),
      },
      rm_approval: opening.rm_approval_date ? dateFormatter(opening.rm_approval_date) : '-',
      team_lead: opening.team_lead.full_name,
      status: opening.status,
      action: {
        status: opening.status,
        job_uuid: job.uuid,
        job_name: job.name,
        service_uuid: opening.uuid,
        opening,
      },
    })),
  }));

const getAction = c =>
  [stepsStatuses.waitingForFinalDecision, stepsStatuses.scheduleInterview, stepsStatuses.sendOffer].includes(
    c.assignment?.status
  )
    ? {
        text: '',
        subtext: '',
        time: '',
        link: '',
        button: {
          label: BUTTON_LABELS[c.assignment.status],
          link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
        },
      }
    : stepsStatuses.provideFeedback === c.assignment?.status
    ? !c.feedback?.is_draft && c.feedback?.posted_at
      ? {
          text: '',
          subtext: '',
          time: '',
          link: '',
          button: '',
        }
      : {
          text: '',
          subtext: '',
          time: '',
          link: '',
          button: {
            label: BUTTON_LABELS[c.assignment.status],
            link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
          },
        }
    : stepsStatuses.waitForResolution === c.assignment?.status
    ? c.start_date && moment(c.start_date, 'YYYY-MM-DD').startOf('day').isAfter(moment().startOf('day'))
      ? {
          text: 'Entry date:',
          subtext: moment(c.start_date, 'YYYY-MM-DD').format('MMM DD, YYYY'),
          time: '',
          link: '',
          button: '',
        }
      : {
          text: '',
          subtext: '',
          time: '',
          link: '',
          button: {
            label: BUTTON_LABELS[c.assignment.status],
            link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
            isFinalDecision: stepsStatuses.waitingForFinalDecision === c.assignment.status,
          },
        }
    : c.expected_answer_date
    ? moment(c.expected_answer_date, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day'))
      ? {
          text: '',
          subtext: '',
          time: '',
          link: '',
          button: {
            label: BUTTON_LABELS[c.assignment.status],
            link: `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`,
            isFinalDecision: stepsStatuses.waitingForFinalDecision === c.assignment.status,
          },
        }
      : {
          text: 'Expected answer date:',
          subtext: moment(c.expected_answer_date).format('MMM DD, YYYY'),
          time: '',
          link: '',
          button: '',
        }
    : c.interview
    ? {
        text: moment(c.interview.date).format('ddd MMM DD, YYYY'),
        subtext: `${dateFormatter(`${c.interview.date} ${c.interview.from_time}`, 'H:mm')} - ${dateFormatter(
          `${c.interview.date} ${c.interview.to_time}`,
          'H:mm'
        )}`,
        time: '',
        link: c.interview.link,
        button: '',
      }
    : c.reminder
    ? {
        text: `Reminder: ${c.reminder.type}`,
        subtext: '',
        time: moment(c.reminder.datetime).format('ddd MMM DD, YYYY [at] H:mm'),
        link: '',
        button: '',
      }
    : {
        text: '',
        subtext: '',
        time: '',
        link: '',
        button: '',
      };

export const candidatesTableDataMapper = candidates =>
  candidates.map((c, index) => ({
    uuid: c.uuid + index,
    candidate: {
      uuid: c.uuid,
      url: c.assignment
        ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
        : `/candidates/${c.uuid}/show`,
      full_name_latin: c.full_name_latin,
      full_name_local: c.full_name_local,
      hot: false,
    },
    level: c.level,
    job: {
      job_name: c.assignment?.job_name,
      service: c.assignment?.service,
      uuid: c.assignment?.job_uuid,
      step_uuid: c.assignment?.step_uuid,
    },
    recruiter: {
      full_name: c.recruiter_full_name,
      uuid: c.recruiter_uuid,
    },
    cvs: c.cvs.map(uuid => ({ file: uuid, uuid })),
    cvs_links: c.cvs_links,
    status_step: {
      status: c.assignment?.status,
      step_number: Object.values(STEPS_LABELS).findIndex(l => l === c.assignment?.step_type) + 1,
      step:
        c.assignment &&
        `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment?.step_type) + 1} - ${c.assignment.step_type}`,
    },
    feedback: c.feedback,
    action: {
      recruiter_uuid: c.recruiter_uuid,
      ...getAction(c),
    },
  }));

export const candidatesActionTableDataMapper = actions =>
  actions.map((a, index) => ({
    uuid: index,
    name: a.group_name,
    count: a.candidates_count,
    candidates: a.candidates.map(c => ({
      uuid: c.uuid,
      candidate: {
        url: c.assignment
          ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
          : `/candidates/${c.uuid}/show`,
        full_name_latin: c.full_name_latin,
        full_name_local: c.full_name_local,
        hot: false,
      },
      level: c.level,
      job: {
        job_name: c.assignment?.job_name,
        service: c.assignment?.service,
      },
      recruiter: {
        full_name: c.recruiter_full_name,
        uuid: c.recruiter_uuid,
      },
      cvs: c.cvs.map(uuid => ({ file: uuid, uuid })),
      cvs_links: c.cvs_links,
      status_step: {
        status: c.assignment?.status,
        step_number: Object.values(STEPS_LABELS).findIndex(l => l === c.assignment?.step_type) + 1,
        step:
          c.assignment &&
          `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment?.step_type) + 1} - ${
            c.assignment.step_type
          }`,
      },
      action: {
        recruiter_uuid: c.recruiter_uuid,
        ...getAction(c),
      },
    })),
  }));

export const candidatesJobTableDataMapper = actions =>
  actions.map((a, index) => ({
    uuid: index,
    name: a.group_name || 'Job not assigned',
    extra: a.service_name,
    count: a.candidates_count,
    candidates: a.candidates.map(c => ({
      uuid: c.uuid,
      candidate: {
        url: c.assignment
          ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
          : `/candidates/${c.uuid}/show`,
        full_name_latin: c.full_name_latin,
        full_name_local: c.full_name_local,
        hot: false,
      },
      level: c.level,
      job: {
        job_name: c.assignment?.job_name,
        service: c.assignment?.service,
      },
      recruiter: {
        full_name: c.recruiter_full_name,
        uuid: c.recruiter_uuid,
      },
      cvs: c.cvs.map(uuid => ({ file: uuid, uuid })),
      cvs_links: c.cvs_links,
      status_step: {
        status: c.assignment?.status,
        step_number: Object.values(STEPS_LABELS).findIndex(l => l === c.assignment?.step_type) + 1,
        step:
          c.assignment &&
          `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment.step_type) + 1} - ${c.assignment.step_type}`,
      },
      action: {
        recruiter_uuid: c.recruiter_uuid,
        ...getAction(c),
      },
    })),
  }));

export const candidatesStepTableDataMapper = actions =>
  actions.map((a, index) => ({
    uuid: index,
    name: a.group_name || 'No step',
    count: a.candidates_count,
    candidates: a.candidates.map(c => ({
      uuid: c.uuid,
      candidate: {
        url: c.assignment
          ? `/jobs/${c.assignment.job_uuid}/show/steps/${c.assignment.step_uuid}/${c.uuid}`
          : `/candidates/${c.uuid}/show`,
        full_name_latin: c.full_name_latin,
        full_name_local: c.full_name_local,
        hot: false,
      },
      level: c.level,
      job: {
        job_name: c.assignment?.job_name,
        service: c.assignment?.service,
      },
      recruiter: {
        full_name: c.recruiter_full_name,
        uuid: c.recruiter_uuid,
      },
      cvs: c.cvs.map(uuid => ({ file: uuid, uuid })),
      cvs_links: c.cvs_links,
      status_step: {
        status: c.assignment?.status,
        step_number: Object.values(STEPS_LABELS).findIndex(l => l === c.assignment?.step_type) + 1,
        step:
          c.assignment &&
          `${Object.values(STEPS_LABELS).findIndex(l => l === c.assignment.step_type) + 1} - ${c.assignment.step_type}`,
      },
      action: {
        recruiter_uuid: c.recruiter_uuid,
        ...getAction(c),
      },
    })),
  }));

export const CANDIDATES_TABLES_MAPPERS = {
  [CANDIDATES_GROUP[0].value]: candidatesTableDataMapper,
  [CANDIDATES_GROUP[1].value]: candidatesActionTableDataMapper,
  [CANDIDATES_GROUP[2].value]: candidatesJobTableDataMapper,
  [CANDIDATES_GROUP[3].value]: candidatesStepTableDataMapper,
};
