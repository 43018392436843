import React from 'react';
import { Modal } from 'antd';
import cn from 'classnames';
import { Button, BUTTON_TYPES } from '_atoms/Button';
import { ReactComponent as Close } from 'dist/icons/close.svg';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';
import './style.scss';

export const Popup = ({ className, children, destroyOnClose = true, loading, ...props }) => (
  <Modal
    destroyOnClose={destroyOnClose}
    className={cn('popup-default', { loading }, className)}
    wrapClassName="popup-default__wrap"
    closeIcon={<Close onClick={props.cancelButtonProps?.onClick || props.okButtonProps?.onClick || props.onClose} />}
    footer={
      <div className="popup-default__footer">
        {props.footerExtra}
        {props.cancelButtonProps && <Button {...props.cancelButtonProps} />}
        {props.okButtonProps && <Button type={BUTTON_TYPES.PRIMARY} {...props.okButtonProps} />}
      </div>
    }
    {...props}
  >
    {children}
    <div className="popup-default__loading">
      <CircularProgress width={40} height={40} />
    </div>
  </Modal>
);
