import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { TextArea, Select, Popup, Input, Button, BUTTON_TYPES } from '_atoms';
import { Typography } from '_atoms/Typography';
import { candidateLevels } from 'constants/constants';
import { JOB_SUBMIT_OPTIONS } from 'constants/job';
import { BUTTON_TEXT } from 'constants/text';
import { useDecisionOptions, useRejectionOptions, dataMapper } from './utils';
import './style.scss';

export const PopupSubmitDecision = ({
  open,
  onClose,
  onSave,
  userData,
  activeStep,
  isFinalDecision,
  candidateLink,
}) => {
  const [level, setLevel] = useState(candidateLevels[0]);
  const [customLevel, setCustomLevel] = useState('');
  const [period, setPeriod] = useState(3);
  const [decision, setDecision] = useState(null);
  const [rejection, setRejection] = useState({ value: 'Other', label: 'Other' });
  const [comment, setComment] = useState('');
  const isManagementApproval = decision?.value === JOB_SUBMIT_OPTIONS.APPROVE && activeStep === 4;

  const onCloseClick = () => {
    setDecision(null);
    setComment('');
    setLevel(candidateLevels[0]);
    setCustomLevel('');
    setPeriod(3);
    setRejection({ value: 'Other', label: 'Other' });
    onClose();
  };

  const onChangeProbationPeriod = e => setPeriod(`0${e.target.value}`.replace(/[^0-9]/g, '').slice(-1));

  const onSubmit = () => {
    let reason = '';
    if (decision?.value !== JOB_SUBMIT_OPTIONS.APPROVE) reason = rejection.value;
    onSave(
      isFinalDecision,
      dataMapper(isManagementApproval, reason, comment, decision?.value, period, level.label, customLevel)
    );
    onCloseClick();
  };

  const decisionOptions = useDecisionOptions(activeStep, false);
  const rejectionOptions = useRejectionOptions(activeStep, decision);

  useEffect(() => {
    if (decision?.value) {
      setRejection(rejectionOptions[0]);
    }
  }, [decision]);

  return (
    <Popup
      open={open}
      title={isFinalDecision ? 'Final decision' : 'Submit decision'}
      cancelButtonProps={{
        onClick: onCloseClick,
        children: BUTTON_TEXT.CANCEL,
      }}
      okButtonProps={{
        children: BUTTON_TEXT.SUBMIT,
        onClick: onSubmit,
        disabled: !decision,
      }}
      className="decision-popup mobile-full-size"
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">
          {userData.full_name_latin || `${userData.first_name_latin} ${userData.last_name_latin}`}
        </Typography.Text>
        <Typography.Caption className="user-info__subtitle">
          {userData.full_name_local || `${userData.first_name_local} ${userData.last_name_local}`}
        </Typography.Caption>
        <Typography.Caption className="user-info__subtitle">{userData.speciality}</Typography.Caption>
        {candidateLink && (
          <a target="_blank" href={candidateLink} rel="noreferrer">
            <Button type={BUTTON_TYPES.TEXT}>view candidate</Button>
          </a>
        )}
      </div>
      <Select
        label="Decision"
        value={decision}
        placeholder="Please select decision"
        options={decisionOptions}
        onChange={setDecision}
        className="margin-bottom-20"
      />
      {decision && decision.value !== JOB_SUBMIT_OPTIONS.APPROVE && (
        <Select
          label="Rejection reason"
          placeholder="Please select step reason for rejection"
          options={rejectionOptions}
          value={rejection}
          onChange={setRejection}
        />
      )}
      {isManagementApproval && (
        <>
          <Row gutter={12} align="bottom">
            <Col span={12}>
              <Input label="Probation period" placeholder="Months" value={period} onChange={onChangeProbationPeriod} />
            </Col>
            <Col span={12}>
              <Typography.Text className="probation-period-text">month(s)</Typography.Text>
            </Col>
          </Row>
          <Row gutter={12} align="bottom">
            <Col span={level.id === 6 ? 12 : 24}>
              <Select
                label="Assumed experience level"
                options={candidateLevels}
                placeholder="Select assumed Technical level"
                value={level}
                onChange={item => {
                  setLevel(item);
                  setCustomLevel('');
                }}
              />
            </Col>
            {level.id === 6 && (
              <Col span={12}>
                <Input
                  placeholder="Level"
                  value={customLevel}
                  onChange={e => setCustomLevel(e.target.value)}
                  maxLength={20}
                />
              </Col>
            )}
          </Row>
        </>
      )}
      <TextArea
        label="Comment"
        value={comment}
        maxLength={6000}
        placeholder="Please enter comment"
        onChange={e => setComment(e.target.value)}
      />
    </Popup>
  );
};
