import { STATUSES } from 'constants/user';
import { STEPS_LABELS } from 'constants/constants';
import { ROLES } from '../../permission';

const LIMIT = 25;

export const LABELS = {
  SERVICE: 'Service',
  JOB: 'Job',
  CURRENT_COMPANIES: 'Current companies',
  TECHNOLOGIES: 'Technologies',
  SPECIALITY: 'Specialty',
  LOCATIONS: 'Locations',
  EXPERIENCE_LEVEL: 'Experience Level',
  COMPANIES: 'Companies',
  COURSES_ATTENDED: 'Courses attended',
  CANDIDATES_STATUSES: 'Candidates status',
  JOB_STEP: 'Job step',
  RESPONSIBLE_RECRUITER: 'Responsible recruiter',
  INTERACTED_BY: 'Interacted by',
  SOURCE: 'Source',
  COUNTRY: 'Country',
  CITY: 'City',
  LAST_INTERACTION: 'Last interaction period',
  ADDED_BY: 'Added by',
  ADDED_DURING: 'Added in period',
  INTERVIEWED_BY: 'Interviewed by',
  INTERVIEWED_DURING: 'Interviewed during',
  INTERVIEW_DATE: 'Interviewed in period',
  CANDIDATE_STATUS: 'Candidate status',
  TAGS: 'Tags',
  TEAM_LEAD: 'Team Lead',
};

export const CANDIDATE_STATUSES = [
  { value: STATUSES.ACTIVE, label: STATUSES.ACTIVE },
  { value: STATUSES.PASSIVE, label: STATUSES.PASSIVE },
  { value: STATUSES.HIRED, label: STATUSES.HIRED },
  { value: STATUSES.BLACKLISTED, label: STATUSES.BLACKLISTED },
  { value: STATUSES.FIRED, label: STATUSES.FIRED },
];

export const EXPERIENCE_LEVEL = [
  { value: 'No Level', label: 'No Level' },
  { value: 'Entry', label: 'Entry' },
  { value: 'Junior', label: 'Junior' },
  { value: 'Middle', label: 'Middle' },
  { value: 'Senior', label: 'Senior' },
  { value: 'Lead', label: 'Lead' },
  { value: 'Custom', label: 'Custom' },
];

export const STEPS_OPTIONS = Object.values(STEPS_LABELS).map(s => ({ value: s, label: s }));

export const OPTIONS_REQUEST_CONFIG = {
  service: search => ({ url: `services/`, body: { limit: LIMIT, has_job: true, search } }),
  team_lead: search => ({ url: `users/`, body: { limit: LIMIT, role: ROLES.TEAM_LEAD, search } }),
  jobs: search => ({ url: `jobs/`, body: { limit: LIMIT, search }, version: 'v2' }),
  speciality: search => ({ url: `candidates/specialities/`, body: { limit: LIMIT, search } }),
  tags: search => ({ url: `candidates/tags/`, body: { limit: LIMIT, search } }),
  companies: search => ({ url: `candidates/companies/`, body: { limit: LIMIT, search } }),
  country: search => ({ url: `candidates/countries/`, body: { limit: LIMIT, search } }),
  responsible_recruiters: search => ({ url: `candidates/responsible-recruiters/`, body: { limit: LIMIT, search } }),
  interacted_by: search => ({
    url: `users/`,
    body: { limit: LIMIT, role: [ROLES.HR_LEAD, ROLES.RECRUITER_LEAD, ROLES.RECRUITER], search },
  }),
  interviewed_by: search => ({
    url: `users/`,
    body: { limit: LIMIT, role: [ROLES.HR_LEAD, ROLES.RECRUITER_LEAD, ROLES.RECRUITER], search },
  }),
  added_by: search => ({
    url: `users/`,
    body: { limit: LIMIT, role: [ROLES.HR_LEAD, ROLES.RECRUITER_LEAD, ROLES.RECRUITER], search },
  }),
};
