import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Col, Row } from 'antd';
import moment from 'moment';
import { AtsSkeleton, Button, BUTTON_TYPES, File, Icon, RichText, Tag } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { getCandidateLoading } from 'store/selectors/commonSelectors';
import './style.scss';

export const ExperienceTabContent = ({ candidateData }) => {
  const loading = useSelector(getCandidateLoading);

  const [specialitiesOpen, setSpecialitiesOpen] = useState(false);

  const onChangeSpecialitiesOpen = () => setSpecialitiesOpen(o => !o);

  if (loading)
    return (
      <div className="job-experience__loading">
        <AtsSkeleton count={3} height={145} />
      </div>
    );

  return (
    <div className="job-experience">
      <div className="job-experience__specialities">
        <Row gutter={[32, 8]}>
          {candidateData.specialities.map((s, index) =>
            !index ? (
              <Col key={s + index} span={12} lg={12} md={12} sm={24}>
                <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>
                  {s.level} {s.speciality}
                </Typography.Title3>
              </Col>
            ) : (
              specialitiesOpen && (
                <Col key={s + index} span={12} lg={12} md={12} sm={24}>
                  <Typography.Text>
                    {s.level} {s.speciality}
                  </Typography.Text>
                </Col>
              )
            )
          )}
        </Row>
        {candidateData.specialities.length > 1 && (
          <Button type={BUTTON_TYPES.TEXT} onClick={onChangeSpecialitiesOpen}>
            {specialitiesOpen ? 'Show Less Specialties' : 'show more specialties'}
          </Button>
        )}
      </div>
      <Row gutter={[32, 0]}>
        <Col span={12}>
          <div className="job-experience__block">
            <Typography.Label className="job-experience__label">Sources</Typography.Label>
            {candidateData.sources.map(s => (
              <div key={s.source}>
                {s.additional_source_name ? (
                  <>
                    <span style={{ color: '#717C8C' }}>
                      {String(s.source).charAt(0).toUpperCase() + String(s.source).slice(1)}:{' '}
                    </span>
                    {s.additional_source_name}
                  </>
                ) : (
                  s.source
                )}
                {candidateData.sources.length > 1 && s.is_main && <strong> (Main)</strong>}
              </div>
            ))}
          </div>
          <div className="job-experience__block">
            <Typography.Label className="job-experience__label">Languages</Typography.Label>
            {candidateData.foreign_languages?.length ? (
              candidateData.foreign_languages.map(lang => (
                <div key={lang.language + lang.level}>{`${lang.language}: ${lang.level}`}</div>
              ))
            ) : (
              <Typography.Text>-</Typography.Text>
            )}
          </div>
          <div className="job-experience__sub-block">
            <Typography.Label className="job-experience__label">Documents</Typography.Label>
            {candidateData.cvs?.length ? (
              candidateData.cvs.map(cv => <File key={cv.uuid} file={cv} />)
            ) : (
              <Typography.Text>-</Typography.Text>
            )}
          </div>
          <div className="job-experience__block">
            <Typography.Label className="job-experience__label">CV links</Typography.Label>
            {candidateData.cvs_links?.length ? (
              candidateData.cvs_links.map((link, id) => (
                <div key={link + id} className="cv-link">
                  <Icon className="cv-link__icon" />
                  <a className="cv-link__text" href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                </div>
              ))
            ) : (
              <Typography.Text>-</Typography.Text>
            )}
          </div>
          <Typography.Label className="job-experience__label">Tags</Typography.Label>
          {candidateData.tags?.length ? (
            candidateData.tags.map(tag => <Tag key={tag}>{tag}</Tag>)
          ) : (
            <Typography.Text>-</Typography.Text>
          )}
        </Col>
        <Col span={12}>
          <Typography.Label className="job-experience__label">Working experience</Typography.Label>
          {candidateData.experience.length ? (
            candidateData.experience.map((exp, idx) => (
              <div
                key={exp.position + idx}
                className={cn({ 'job-experience__sub-block': idx !== candidateData.experience.length - 1 })}
              >
                <Typography.Title4>{exp.company}</Typography.Title4>
                <Typography.Caption>
                  {`${exp.worked_from ? moment(exp.worked_from).format('MM.YYYY') : 'no date'} - ${
                    exp.is_current ? 'current' : exp.worked_till ? moment(exp.worked_till).format('MM.YYYY') : 'no date'
                  } | ${exp.position}`}
                </Typography.Caption>
                <RichText text={exp.additional_info} />
              </div>
            ))
          ) : (
            <Typography.Text>-</Typography.Text>
          )}
        </Col>
      </Row>
    </div>
  );
};
